@use "@angular/material" as mat;

@include mat.core();

@import "bootstrap/scss/bootstrap";
@import "normalize.css";
@import "app/shared/theme.scss";

// Include all theme styles for the components.
@include mat.all-component-themes($light-theme);
@include mat.typography-hierarchy($light-theme);

@mixin light-theme {
  --calendall-base-color: #f07ca4;
  --mat-calendar-background-main: #{mat.get-theme-color(
      $light-theme,
      background,
      background
    )};
  --mat-calendar-background-secondary: #{mat.get-theme-color(
      $light-theme,
      background,
      app-bar
    )};
  --mat-calendar-background-hover: #{mat.get-theme-color(
      $light-theme,
      background,
      hover
    )};
  --mat-calendar-border: #e1e1e1;
  --mat-calendar-not-in-working-hours-background: repeating-linear-gradient(
      -60deg,
    #{mat.get-theme-color($light-theme, background, background)},
      #{mat.get-theme-color($light-theme, background, background)} 9px,
      #{darken(mat.get-theme-color($light-theme, background, app-bar), 10%)} 1px,
    #{darken(mat.get-theme-color($light-theme, background, app-bar), 10%)} 15px
  );
  --mat-calendar-today-color: #e8fde7;
  --mat-calendar-weekend-color: #8b0000;
  --mat-notification-container-background: #fff;
}

@mixin dark-theme {
  --calendall-base-color: #f07ca4;
  --mat-calendar-background-main: #{mat.get-theme-color(
      $dark-theme,
      background,
      background
    )};
  --mat-calendar-background-secondary: #{mat.get-theme-color(
      $dark-theme,
      background,
      app-bar
    )};
  --mat-calendar-background-hover: #{mat.get-theme-color(
      $dark-theme,
      background,
      hover
    )};
  --mat-calendar-border: #595959;
  --mat-calendar-not-in-working-hours-background: repeating-linear-gradient(
      -60deg,
    #{lighten(mat.get-theme-color($dark-theme, background, app-bar), 20%)},
      #{lighten(mat.get-theme-color($dark-theme, background, app-bar), 20%)} 9px,
      #{lighten(mat.get-theme-color($dark-theme, background, app-bar), 10%)} 1px,
    #{lighten(mat.get-theme-color($dark-theme, background, app-bar), 10%)} 15px
  );
  --mat-calendar-today-color: rgb(51, 182, 121);
  --mat-calendar-weekend-color: rgb(255, 92, 92);
  --mat-notification-container-background: #{mat.get-theme-color(
      $dark-theme,
      background,
      tooltip
    )};
}

@include mat.all-component-colors($light-theme);
:root {
  @include light-theme;
}

@media (prefers-color-scheme: dark) {
  @include mat.all-component-colors($dark-theme);
  :root {
    @include dark-theme;
  }
}

// ligth and dark theme classes will override the default browser behaviour if selected in the settings
.light-theme {
  @include mat.all-component-colors($light-theme);
  @include light-theme;
}

.dark-theme {
  @include mat.all-component-colors($dark-theme);
  @include dark-theme;
}

.theme-background-color {
  background-color: var(--mat-calendar-background-main);
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.flex-fill-evenly {
  flex: 1 1 10em;
}

mat-dialog-actions.button-bar, div.button-bar, mat-card-actions.button-bar {

}

@media (min-width: 600px) {
  mat-dialog-actions.button-bar, div.button-bar, mat-card-actions.button-bar {
    flex-direction: row;
    align-items: center;

    button {
      min-width: 200px;
    }
  }
}

.calendall-color-bubble {
  width: 1em;
  height: 1em;
  border-radius: 100%;
}

.calendall-color-bubble-lg {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

tr.mat-row:not(.expanded-row):hover {
  background: #aaa;
}

.calendall-tooltip {
  font-size: 1em;
  text-align: center;
}

.mat-background-primary {
  background-color: #3f51b5;
}

mat-option > span,
.mat-mdc-menu-item > span {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: 4px;
  align-items: inherit;
}

mat-dialog-content {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

mat-dialog-content::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.sticky-0 {
  position: sticky;
  position: -webkit-sticky; /* Safari */
  top: 0;
  height: 100px;
  z-index: 10;
  background-color: var(--bs-body-bg);
}

.sticky-100px {
  position: sticky;
  position: -webkit-sticky; /* Safari */
  top: 100px;
  z-index: 10;
}

.country-selector,
.country-list-button {
  color: var(--mdc-theme-text-primary-on-background) !important;
}
ngx-mat-intl-tel-input button.country-selector {
  opacity: 1 !important;
}

.cursor-default {
  cursor: default;
}

.cal-week-view app-calendar-week-view-event .cal-event {
  border-width: 3px;
}

.highlight {
  background-color: yellow;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.world-icon:before {
  font-weight: normal;
  font-family: "Material Icons";
  content: "\e894";
}

.repeat-event-icon:before {
  font-weight: bold;
  font-family: "Material Icons";
  content: "\e042";
}

.highlight-hover-row tr.mat-mdc-row:hover {
  background-color: var(--mat-calendar-background-hover);
  cursor: pointer;
}


/* Safari-specific adjustment */
@media not all and (min-resolution: .001dpcm) {
  @supports (-webkit-appearance: none) {
    app-appointment-calendar .cal-event-title div :first-child {
      -webkit-font-smoothing: antialiased;
      font-weight: 500;
    }
  }
}
